import axios from "axios";
import { OrderItemsProps, OrderProps } from "./Props";
import { FrCustomeErrorNorify } from "./Toastify";
import OrderItems from "../pages/order-management/OrderItems";


export const getBase64 = async (imageUrl: string, entity: string): Promise<string> => {
    try {
        const formData = new FormData();
        formData.append('imageUrl', imageUrl);
        formData.append('entity', entity);

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/convert-blob`, formData, {
            responseType: 'blob',
        });

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    reject(new Error('FileReader result is not a string'));
                }
            };
            reader.onerror = () => {
                reject(new Error('Error reading the blob as base64'));
            };
        });
    } catch (error) {
        FrCustomeErrorNorify();
        throw error;
    }
};


export const fetchBase64Images = async (order: OrderProps) => {

    const imagePromises = order.orderItems.map(async (o) => {
        const imageUrl = `${o.product ? `${o.product.image}` : `${o.pack?.image}`}`;
        try {
            const base64 = await getBase64(imageUrl, o.product ? 'Prod' : 'Pack');
            return base64;
        } catch (error) {
            // console.error('Error fetching base64:', error);
            return null;
        }
    });

    const images = await Promise.all(imagePromises);
    return images
};


export const fetchBase64ImagesState = async (order: OrderProps, setBase64Images?: (images: (string | null)[]) => void) => {
    if (!order?.orderItems) {
        if (setBase64Images) {
            setBase64Images([]);
            return;
        }
    }

    const imagePromises = order.orderItems.map(async (o) => {
        const imageUrl = `${o.product ? `${o.product.image}` : `${o.pack?.image}`}`;
        try {
            const base64 = await getBase64(imageUrl, o.product ? 'Prod' : 'Pack');
            return base64;
        } catch (error) {
            // console.error('Error fetching base64:', error);
            return null;
        }
    });

    const images = await Promise.all(imagePromises);
    if (setBase64Images) {
        setBase64Images(images);
    }

    return images
};



export const calculateTotalPrice = (orderItems: OrderItemsProps[]): number => {
    return orderItems.reduce((total, item) => {
        return total + (item.price * item.quantity);
    }, 0);
}


export const apiUrl = process.env.REACT_APP_API_URL
export const uploadUrl = process.env.REACT_APP_API_UPLOAD
export const productImgUrl = `${uploadUrl}/product_images`
export const PMultiImgUrl = `${uploadUrl}/product_multi_images`
export const variantImgUrl = `${uploadUrl}/variant_images`
export const variantIconUrl = `${uploadUrl}/variant_icons`
export const VMultiImgUrl = `${uploadUrl}/variant_multi_images`
export const packImgUrl = `${uploadUrl}/pack_images`
export const marqueLogoUrl = `${uploadUrl}/marque_logos`
export const marqueImgUrl = `${uploadUrl}/marque_images`
export const categorieImgUrl = `${uploadUrl}/category_images`
export const sliderImgUrl = `${uploadUrl}/slider_images`
export const specialOffersImgUrl = `${uploadUrl}/offer_images`
export const blogImgUrl = `${uploadUrl}/blog_images`
export const blogMultiImgUrl = `${uploadUrl}/blog_multi_images`
export const blogPostImgUrl = `${uploadUrl}/blog_post_images`