import React, { useEffect, useState } from 'react'
import { useFormik, FormikProvider, FieldArray } from 'formik'
import * as Yup from 'yup'
import { useApiRequest } from '../../helpers/ApiRequest'
import { useParams } from 'react-router-dom'
import { OrderProps, OrderItemsProps } from '../../helpers/Props'
import { Loading } from '../../components/Loading'
import SubmitButton from '../../components/submitButton/submitButton'
import { UpdateNotify, FrCustomeErrorNorify, DeleteNotify } from '../../helpers/Toastify'

const UpdateOrder: React.FC = () => {
    const apiRequest = useApiRequest()
    const { id } = useParams<{ id: string }>()
    const [order, setOrder] = useState<OrderProps | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isDeletingItem, setIsDeletingItem] = useState(false)

    // Fetch order data
    const fetchOrderData = async () => {
        try {
            const response = await apiRequest({
                route: `orders/${id}`,
                method: 'GET',
            })

            if (response.status === 200) {
                setOrder(response.data)

                // Initialize Formik values
                formik.setValues({
                    reference: response.data.reference || '',
                    orderStatus: response.data.orderStatus || '',
                    paymentStatus: response.data.paymentStatus || '',
                    totalPrice: response.data.totalPrice || 0,
                    itemsQuantity: response.data.itemsQuantity || 0,
                    orderItems: response.data.orderItems.map((item: OrderItemsProps) => ({
                        id: item.id,
                        product: item.product,
                        quantity: item.quantity,
                        price: item.price,
                    })),
                })
            }
        } catch (error) {
            FrCustomeErrorNorify()
        }
    }

    useEffect(() => {
        fetchOrderData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Validation Schema
    const validationSchema = Yup.object().shape({
        reference: Yup.string().required('Reference is required'),
        orderStatus: Yup.string().required('Order status is required'),
        paymentStatus: Yup.string().required('Payment status is required'),
        totalPrice: Yup.number().required('Total price is required').min(1, 'Price must be at least 1'),
        itemsQuantity: Yup.number().required('Items quantity is required').min(1, 'Quantity must be at least 1'),
        orderItems: Yup.array().of(
            Yup.object().shape({
                id: Yup.number(),
                product: Yup.object().required('Product is required'),
                quantity: Yup.number()
                    .required('Quantity is required')
                    .min(1, 'Quantity must be at least 1'),
                price: Yup.number()
                    .required('Price is required')
                    .min(0, 'Price must be at least 0'),
            })
        ),
    })

    const formik = useFormik({
        initialValues: {
            reference: '',
            orderStatus: '',
            paymentStatus: '',
            totalPrice: 0,
            itemsQuantity: 0,
            orderItems: [] as Array<{
                id: number
                product: any
                quantity: number
                price: number
            }>,
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsLoading(true)
            try {
                const adjustedOrderItems = values.orderItems.map((item) => ({
                    id: item.id,
                    product: `api/products/${item.product.id}`,
                    quantity: item.quantity,
                    price: item.price,
                }))

                const data = {
                    reference: values.reference,
                    orderStatus: values.orderStatus,
                    paymentStatus: values.paymentStatus,
                    totalPrice: values.totalPrice,
                    itemsQuantity: values.itemsQuantity,
                    orderItems: adjustedOrderItems,
                }

                // console.log(data);
                

                const response = await apiRequest({
                    route: `order/update/${id}`,
                    method: 'PUT',
                    body: data,
                })

                if (response.status === 200) {
                    UpdateNotify('Order')
                    setIsLoading(false)
                }
            } catch (error) {
                FrCustomeErrorNorify()
                setIsLoading(false)
            }
        },
    })

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = formik

    // Update total price and items quantity when order items change
    useEffect(() => {
        const totalPrice = values.orderItems.reduce(
            (sum, item) => sum + item.price * item.quantity,
            0
        )

        const itemsQuantity = values.orderItems.length

        setFieldValue('totalPrice', totalPrice)
        setFieldValue('itemsQuantity', itemsQuantity)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.orderItems])

    // Function to delete order item
    const deleteOrderItem = async (index: number, itemId: number) => {
        setIsDeletingItem(true)
        try {
            // Delete order item via API
            const response = await apiRequest({
                route: `order_items/${itemId}`,
                method: 'DELETE',
            })

            if (response.status === 204) {
                DeleteNotify('Order Item')

                // Remove item from Formik values
                const updatedOrderItems = [...values.orderItems]
                updatedOrderItems.splice(index, 1)
                // Update the Formik state with the updated orderItems
                setFieldValue('orderItems', updatedOrderItems)

                // Recompute totalPrice and itemsQuantity
                const totalPrice = updatedOrderItems.reduce(
                    (sum, item) => sum + item.price * item.quantity,
                    0
                )
                const itemsQuantity = updatedOrderItems.length
                setFieldValue('totalPrice', totalPrice)
                setFieldValue('itemsQuantity', itemsQuantity)

                // Send updated order to backend
                await updateOrderBackend(updatedOrderItems, totalPrice, itemsQuantity)

                setIsDeletingItem(false)
            }
        } catch (error) {
            FrCustomeErrorNorify()
            setIsDeletingItem(false)
        }
    }

    // Function to update order on backend
    const updateOrderBackend = async (updatedOrderItems: any, totalPrice: number, itemsQuantity: number) => {
        try {
            const adjustedOrderItems = updatedOrderItems.map((item: any) => ({
                id: item.id,
                product: `api/products/${item.product.id}`,
                quantity: item.quantity,
                price: item.price,
            }))

            const data = {
                reference: values.reference,
                orderStatus: values.orderStatus,
                paymentStatus: values.paymentStatus,
                totalPrice: totalPrice,
                itemsQuantity: itemsQuantity,
                orderItems: adjustedOrderItems,
            }

            console.log(data);
            

            const response = await apiRequest({
                route: `order/update/${id}`,
                method: 'PUT',
                body: data,
            })

            if (response.status === 200) {
                UpdateNotify('Order updated after item deletion')
            }
        } catch (error) {
            FrCustomeErrorNorify()
        }
    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#order_details'
                aria-expanded='true'
                aria-controls='order_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Update Order</h3>
                </div>
            </div>

            <div id='order_details' className='collapse show'>
                <form onSubmit={handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        {/* Order Information */}
                        <div className='row mb-6'>
                            <div className='col-lg-4 col-md-4 fv-row'>
                                <label className='col-form-label required fw-bold fs-6'>
                                    Reference
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Reference'
                                    name='reference'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.reference}
                                    readOnly
                                />
                                {touched.reference && errors.reference && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{errors.reference}</div>
                                    </div>
                                )}
                            </div>

                            <div className='col-lg-4 col-md-4 fv-row'>
                                <label className='col-form-label required fw-bold fs-6'>
                                    Order Status
                                </label>
                                <select
                                    name='orderStatus'
                                    className='form-select form-select-solid'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.orderStatus}
                                >
                                    <option value='' disabled>
                                        Select Status
                                    </option>
                                    <option value='pending'>Pending</option>
                                    <option value='validate'>Validated</option>
                                    <option value='shipping'>Shipping</option>
                                    <option value='annuler'>Cancelled</option>
                                </select>
                                {touched.orderStatus && errors.orderStatus && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{errors.orderStatus}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Payment Status and Totals */}
                        <div className='row mb-6'>
                            <div className='col-lg-4 col-md-4 fv-row'>
                                <label className='col-form-label fw-bold fs-6'>
                                    Prix Total
                                </label>
                                <input
                                    type='text'
                                    min={1}
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Prix Total'
                                    name='totalPrice'
                                    value={values.totalPrice}
                                    readOnly
                                />
                            </div>

                            <div className='col-lg-4 col-md-4 fv-row'>
                                <label className='col-form-label fw-bold fs-6'>
                                    Nbr Articles
                                </label>
                                <input
                                    type='text'
                                    min={1}
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Nbr Articles'
                                    name='itemsQuantity'
                                    value={values.itemsQuantity}
                                    readOnly
                                />
                            </div>
                        </div>

                        {/* Order Items */}
                        <div className='row mb-6'>
                            <div className='col-lg-12 col-md-12 fv-row'>
                                <h4 className='fw-bold mb-3'>Order Items</h4>
                                <FormikProvider value={formik}>
                                    <FieldArray
                                        name='orderItems'
                                        render={(arrayHelpers) => (
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        {/* <th>id</th> */}
                                                        <th>Product</th>
                                                        <th>Prix</th>
                                                        <th>Quantity</th>
                                                        <th>Total</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {values.orderItems.map((item, index) => (
                                                        <tr key={item.id}>
                                                            {/* <td>{item.id}</td> */}
                                                            <td>{item.product.title}</td>
                                                            <td>
                                                                <input
                                                                    type='number'
                                                                    min={1}
                                                                    name={`orderItems[${index}].price`}
                                                                    className='form-control'
                                                                    value={item.price}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type='number'
                                                                    min={1}
                                                                    name={`orderItems[${index}].quantity`}
                                                                    className='form-control'
                                                                    value={item.quantity}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </td>
                                                            <td>{(item.price * item.quantity).toFixed(2)}</td>
                                                            <td>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-icon btn-light-danger btn-sm'
                                                                    onClick={() =>
                                                                        deleteOrderItem(index, item.id)
                                                                    }
                                                                    disabled={isDeletingItem}
                                                                >
                                                                    {isDeletingItem ? (
                                                                        <Loading />
                                                                    ) : (
                                                                        <i className='bi bi-trash-fill fs-4'></i>
                                                                    )}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    />
                                </FormikProvider>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <SubmitButton
                            isLoading={isLoading}
                            buttonLabel='Enregistrer les modifications'
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UpdateOrder
